export default function standardServerErrorHandler(err) {
  if ( err.response ) {
    if ( err.response.status == 422 ) {
      this.setState({ errors: err.response.data.errors, isLoading: false });
    } else if ( err.response.status == 404 ) {
      this.props.history.push('/not_found');
    } else {
      this.props.history.push('/server_error');
    }
  }
  else {
    console.log(err);
  }
};
