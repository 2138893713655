import { SET_CURRENT_USER } from '../actions/types'
import isEmpty from 'lodash/isEmpty'

const initialState = {
  isAuthenticated: false,
  user: {}
}

export default (state = initialState, action = {}) => {
  switch(action.type) {
    case SET_CURRENT_USER:
      var user = action.user ? action.user.user || action.user : {}

      return {
        isAuthenticated: !isEmpty(user),
        user: user
      }
    default: return state
  }
}
