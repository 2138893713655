export default function formInputValueToObject(inputName, value, toObject = {}, splitter = '.') {
  var namespaces = inputName.split(splitter);
  var currentLevel = toObject;

  namespaces.forEach(function(key, index, array){
    if(namespaces.length > index + 1){
      currentLevel[key] = currentLevel[key] || {};
      currentLevel = currentLevel[key];
    }
    else{
      currentLevel[key] = value;
    }   
  });

  return toObject;
};
