import React, { Component } from 'react'
import { withRouter } from 'react-router'
import currentUrl from 'utils/currentUrl'

class LiveinternetCounter extends Component {
  constructor(props) {
    super(props)
    this.state = { referer: document.referer }
  }

  componentWillReceiveProps(nextProps) {
    this.setState( { referer: this.props.location.pathname } )
  }

  render() {
    var src = "//counter.yadro.ru/hit?t38.6;r"
    src += escape(this.state.referer)

    src += (
      (typeof(screen)=="undefined") ? "" : ";s" + screen.width + "*" + screen.height + "*" + ( screen.colorDepth ? screen.colorDepth : screen.pixelDepth )
    )

    src += ";u" + escape(currentUrl()) + ";h" + escape(document.title.substring(0,150)) + ";" + Math.random()

    return (
      <a href='//www.liveinternet.ru/click' target='_blank'>
        <img id="liru" src={ src } alt='' title='LiveInternet' border='0' width='31' height='31' />
      </a>
    )
  }

}

export default withRouter(LiveinternetCounter)
