import React, { Component } from 'react'
import Helmet from 'react-helmet'

export default class PageMeta extends Component {
  render() {
    const props = this.props

    return (
      <Helmet>
        { props.title && <title>{ props.title }</title> }
        { props.description && <meta name="description" content={ props.description } /> }
        { props.keywords && <meta name="keywords" content={ props.keywords } /> }
        { props.title && <meta property="og:title" content={ props.title } /> }
        { props.description && <meta property="og:description" content={ props.description } /> }
        { props.url && <meta property="og:url" content={ props.url } /> }
        { props.canonical_url && <link rel="canonical" href={ props.canonical_url } /> }
        { props.amp_url && <link rel="amphtml" href={ props.amp_url } /> }
        { props.prerender_status_code && <meta name="prerender-status-code" content={ props.prerender_status_code } /> }

        <meta property="og:image" content={ props.image_url || require('images/fblogo.png') } />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={ _t('rosagrodel') } />
      </Helmet>
    );
  }
}
