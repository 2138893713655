import React, { Component, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import ScrollMemory from 'react-router-scroll-memory'

import {
  Route,
  Switch,
  withRouter,
  Link
} from 'react-router-dom'

import PrivateRoute from 'components/PrivateRoute'
import Header from './Header'
import Footer from './Footer'
import MainMenu from 'components/MainMenu'

const MainPage = lazy(() => import('./MainPage'))
const LoginPage = lazy(() => import('./LoginPage'))
const SignupPage = lazy(() => import('./SignupPage'))
const ForgotPasswordPage = lazy(() => import('./ForgotPasswordPage'))
const UserVerifyPage = lazy(() => import('./users/UserVerifyPage'))
const ChangeForgottenPasswordPage = lazy(() => import('./ChangeForgottenPasswordPage'))
const PartnersPage = lazy(() => import('./PartnersPage'))
const UserPage = lazy(() => import('./users/UserPage'))

const UnsubscribePage = lazy(() => import('./UnsubscribePage'))
const DisableMailingPage = lazy(() => import('./DisableMailingPage'))

const NotFoundPage = lazy(() => import('./NotFoundPage'))
const ServerErrorPage = lazy(() => import('./ServerErrorPage'))

const SectionPage = lazy(() => import('./SectionPage'))
const StaticPage = lazy(() => import('./StaticPage'))

const ItemsPage = lazy(() => import('./items/ItemsPage'))
const ItemShowPage = lazy(() => import('./items/ItemShowPage'))
const ItemsCityPage = lazy(() => import('./items/ItemsCityPage'))
const RecommendedPage = lazy(() => import('./items/RecommendedPage'))
const NewestPage = lazy(() => import('./items/NewestPage'))

const MaterialsPage = lazy(() => import('./materials/MaterialsPage'))
const MaterialShowPage = lazy(() => import('./materials/MaterialShowPage'))

const CompaniesPage = lazy(() => import('./companies/IndexPage'))
const ConsultantsPage = lazy(() => import('./counseling/consultants/index/IndexPage'))

const ProfileRoutesContainer = lazy(() => import('./ProfileRoutesContainer'))
const JobRoutesContainer = lazy(() => import('./job/RoutesContainer'))

import PageMeta from 'components/PageMeta'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
ReactGA.initialize('G-K2YW21YX3G')
ReactPixel.init('1260276627467543', {}, { autoConfig: true, debug: false })
import ym, {YMInitializer} from 'react-yandex-metrika'
import currentUrl from 'utils/currentUrl'

// ----------------------------------------------------------------------------

class App extends React.Component {

  constructor(props) {
    super(props)

    if (props.session.user.id) {
      ReactGA.set({ userId: props.session.user.id, userType: props.session.user.type })
      // ym(47572006, 'userParams', {
      //   UserType: props.session.user.type,
      //   UserID: props.session.user.id
      // });
    }
  }

  componentDidMount() {
    // window.Ya.adfoxCode.createAdaptive({
    //     ownerId: 255627,
    //     containerId: 'adfox_153795167830274195',
    //     params: {
    //         p1: 'cbzhs',
    //         p2: 'ftiu'
    //     }
    // }, ['desktop'], {
    //     tabletWidth: 1020,
    //     phoneWidth: 760,
    //     isAutoReloads: false
    // });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.session.user.id) {
      ReactGA.set({ userId: nextProps.session.user.id, userType: nextProps.session.user.type })
      ym('userParams', {
        UserType: nextProps.session.user.type,
        UserID: nextProps.session.user.id
      });
    } else {
      ReactGA.set({ userId: null, userType: null })
      ym('userParams', {
        UserType: undefined,
        UserID: undefined
      })
    }

    if (this.props.location.pathname !== nextProps.location.pathname) {
      Object.defineProperty(document, "referrer", {
        value: (BACKEND_HOST + this.props.location.pathname).trim(),
        configurable: true
      })
    }
  }

  render() {
    const { user, isAuthenticated } = this.props.session
    const title = _t('pagesMeta.default.title')
    const description = _t('pagesMeta.default.description')

    const logPageView = () => {
      var path = window.location.pathname
      // setTimeout(function() { ahoy.trackView() }, 1000)
      ReactGA.set({ page: path })
      // ReactGA.pageview(window.location.pathname + window.location.search)
	  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: title });
      ReactPixel.pageView()
      return null
    }

    return (
      <React.Fragment>
        <ScrollMemory />

        <div className="b-wrapper">

          <PageMeta
            title={ title }
            description={ description }
            url={ currentUrl() }
            image_url={ require('images/fblogo.png') }
          />

          <Route component={logPageView} />
          <YMInitializer accounts={[47572006]} options={{clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true, userParams: {UserID: user.id, UserType: user.type}}} />

          <header className="b-header">
            <Header />
            <MainMenu />		
          </header>

          <main className="b-content">
            <Suspense fallback={
              <div className="center">
                { _t('loading') }
              </div>
            }>
              <Switch>
                <Route exact path='/' component={MainPage} />
                <Route path='/login' component={LoginPage} />
                <Route path='/signup' component={SignupPage} />
                <Route path='/forgot_password' component={ForgotPasswordPage} />
                <Route path='/unsubscribe' component={UnsubscribePage} />
                <Route path='/partners' component={PartnersPage} />
                <Route path='/disable_mailing' component={DisableMailingPage} />

                <Route path='/goods' render={(props) => <SectionPage {...props} section='goods'/>} />
                <Route path='/service' render={(props) => <SectionPage {...props} section='service'/>} />
                <Route path='/lease' render={(props) => <SectionPage {...props} section='lease'/>} />
                <Route path='/realty' render={(props) => <SectionPage {...props} section='realty'/>} />

                <Route path='/companies' component={CompaniesPage} />

                <Route path='/page/:pageId' component={StaticPage} />

                <Route path='/items/recommended' component={RecommendedPage} />
                <Route path='/items/newest' component={NewestPage} />
                <Route path='/items/:itemId(\d+)' component={ItemShowPage} />
                <Route path='/items/:itemId(\d+)-:sefTitle' component={ItemShowPage} />
                <Route path='/items/:rubric_path(\D+)?' component={ItemsPage} />
                <Route path='/cities/:cityId(\d+)/:rubricPath(\D+)?' component={ItemsCityPage} />
                <Route path='/cities/:cityId(\d+)-:sefName/:rubricPath(\D+)?' component={ItemsCityPage} />

                <Route path='/materials/:materialId(\d+)' component={MaterialShowPage} />
                <Route path='/materials/:materialId(\d+)-:sefTitle' component={MaterialShowPage} />
                <Route path='/materials/:rubric_path(\D+)?' component={MaterialsPage} />

                <Route path='/users/:userId/verify' component={UserVerifyPage} />
                <Route path='/users/:userId/change_forgotten_password' component={ChangeForgottenPasswordPage} />
                <Route path='/users/:userId/info' render={() => <UserPage tab='info' />} />
                <Route path='/users/:userId/items' render={() => <UserPage tab='items' />} />
                <Route path='/users/:userId/job' render={() => <UserPage tab='job' />} />
                <Route path='/users/:userId/counseling' render={() => <UserPage tab='counseling' />} />
                <Route path='/users/:userId/partners' render={() => <UserPage tab='partners' />} />
                <Route path='/users/:userId/dashboard' render={() => <UserPage tab='dashboard' />} />
                <Route path='/users/:userId' render={() => <UserPage tab='dashboard' />} />

                <Route path='/consultants' component={ConsultantsPage} />
                <Route path='/job' component={JobRoutesContainer} />

                <PrivateRoute path='/profile' component={ProfileRoutesContainer} />

                <Route path='/not_found' component={NotFoundPage} />
                <Route path='/server_error' component={ServerErrorPage} />
                <Route component={NotFoundPage} />
              </Switch>
            </Suspense>
          </main>

          <Footer />

{/*
		  <div class="b-dashboardimgpopup">
			<div class="b-dashboardimgpopup__img">
				<a href="#" class="b-dashboardimgpopup__link"></a>
				<a href="#" class="b-dashboardimgpopup__close"></a>
			</div>
		  </div>
*/}

        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    session: state.auth
  }
}

export default withRouter(connect(mapStateToProps)(App))
