import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MainLink from 'components/helpers/MainLink'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Panel } from 'react-bootstrap'

import TopBarAlerts from 'components/TopBarAlerts'

const onMobileMenuClick = () => {
  let el = document.getElementById('mobile-menu--heading')
  el.querySelector('[role=button]').click()
}

class MainMenu extends Component {
  render() {
    const { user, isAuthenticated } = this.props.session
    var path = this.props.location.pathname

    return (
  		<React.Fragment>

        <TopBarAlerts />

      	<nav className="b-header__navigation">
      		<ul className="b-mainmenu">
            <li className={ path === '/' ? 'active' : '' }>
              <MainLink to="/">{ _t('main') }</MainLink>
            </li>

            <li className={ /\/goods/.test(path) ? 'active' : '' }>
              <MainLink to="/goods">{ _t('goods') }</MainLink>
            </li>

      			<li className={ /\/service/.test(path) ? 'active' : '' }>
              <MainLink to="/service">{ _t('services') }</MainLink>
            </li>

            <li className={ /\/lease/.test(path) ? 'active' : '' }>
              <MainLink to="/lease">{ _t('lease') }</MainLink>
            </li>

            <li className={ /\/realty/.test(path) ? 'active' : '' }>
              <MainLink to="/realty">{ _t('realty') }</MainLink>
            </li>

            <li className={ /\/job/.test(path) ? 'active' : '' }>
              <MainLink to="/job">{ _t('job') }</MainLink>
            </li>

      			<li className={ /\/companies/.test(path) ? 'active' : '' }>
              <MainLink to="/companies">{ _t('participants') }</MainLink>
            </li>

            <li className={ /\/items\/market/.test(path) ? 'active' : '' }>
              <MainLink to="/items/market">{ _t('market') }</MainLink>
            </li>
      		</ul>
      	</nav>

    		<nav className="b-header__mobilenavigation" onClick={ onMobileMenuClick }>
      		<Panel id="mobile-menu" className="b-mobilemenu">
      			<Panel.Heading className="b-mobilemenu__hamburger">
      				<Panel.Title componentClass="span" className="b-mobilemenu__hamburgerbox" toggle>
      					{ _t('menu') }
      				</Panel.Title>
      			</Panel.Heading>

      			<Panel.Collapse className="b-mobilemenu__mainmenu">
      				<Panel.Body>
      					<ul className="b-mainmenu mobile">
      					  <li className={ path === '/' ? 'active' : '' }><MainLink to="/">{ _t('main') }</MainLink></li>
        					<li className={ /\/goods/.test(path) ? 'active' : '' }><MainLink to="/goods">{ _t('goods') }</MainLink></li>
        					<li className={ /\/service/.test(path) ? 'active' : '' }><MainLink to="/service">{ _t('services') }</MainLink></li>
        					<li className={ /\/lease/.test(path) ? 'active' : '' }><MainLink to="/lease">{ _t('lease') }</MainLink></li>
        					<li className={ /\/realty/.test(path) ? 'active' : '' }><MainLink to="/realty">{ _t('realty') }</MainLink></li>
        					<li className={ /\/job/.test(path) ? 'active' : '' }><MainLink to="/job">{ _t('job') }</MainLink></li>
        					<li className={ /\/companies/.test(path) ? 'active' : '' }><MainLink to="/companies">{ _t('participants') }</MainLink></li>
        					<li className={ /\/items\/market/.test(path) ? 'active' : '' }><MainLink to="/items/market">{ _t('market') }</MainLink></li>
      					</ul>
      				</Panel.Body>
      			</Panel.Collapse>
      		</Panel>
    		</nav>

  		</React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    session: state.auth
  }
}

export default withRouter(connect(mapStateToProps)(MainMenu))
