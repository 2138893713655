import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class BankImage extends Component {
  render() {
    const { file, size = '300x228', className = 'img-responsive', alt, replacement } = this.props
  
    if (file && file != undefined ) {
      return (
        <img src={ `/b/c/${file.file_id}.${size}.jpg` } className={ className } alt={ alt } title={ alt } />
      )
    } else {
      if (replacement) {        
        return(
          <img className={ className } src={ replacement } alt={ alt } />
        )
      } else {
        return null
      }
    }
  }
}
