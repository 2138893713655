import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

class PrivateRoute extends Component {
  render() {
    const { user, isAuthenticated } = this.props.session

    const Komponent = this.props.component
    const rest = Object.assign({}, this.props)
    const data = this.props.data
    delete rest.component

    let isAuthorized = true

    if ( this.props.only_for_admin ) {
      isAuthorized = user.is_admin
    }

    if ( this.props.only_for_publisher ) {
      isAuthorized = user.is_publisher
    }

    return (
      <Route { ...rest } render={ props => {
        return (
          isAuthenticated ? (
            isAuthorized ? (
              <Komponent { ...props } { ...data } />
            ) : (
              <Redirect to={{
                pathname: '/profile',
              }} />
            )
          ) : (
            <Redirect to={{
              pathname: '/login',
              state: { from: props.location }
            }} />
          )
        )
      }} />
    )
  }
}

function mapStateToProps(state) {
  return {
    session: state.auth
  }
}

export default connect(mapStateToProps)(PrivateRoute)
