import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Feedback from 'components/Feedback'
import LiveinternetCounter from 'components/LiveinternetCounter'

const tN = 'components.Footer.'

export default class Footer extends Component {
  render() {
    return (
      <footer className="b-footer">
        <ul className="b-footer__menu1">
          <Feedback />
          <li>
            <Link to="/page/rules">{ _t('termsOfUse') }</Link>
          </li>
          <li>
            <Link to="/page/about">{ _t('aboutUs') }</Link>
          </li>
          <li className="partners">
            <Link to="/partners">{ _t('partners') }</Link>
          </li>
        </ul>

        <ul className="b-footer__menu2">
          <li>
            <Link to="/page/useragreement">{ _t('userAgreement') }</Link>
          </li>
          <li>
            <Link to="/page/confidentiality">{ _t('privacyAgreement') }</Link>
          </li>
        </ul>

        <p className="b-footer__text">
          { _t(tN + 'text') } <Link className="text-nowrap" to="/page/rules">{ _t(tN + 'rulesLinkTitle') }</Link>
        </p>

        <p className="b-footer__text">{ _t(tN + 'text2') }</p>

        <p className="b-footer__copyright">
          © <span className="b-logo-text">rosagrodel<span>.ru</span></span>, { (new Date()).getFullYear() }
        </p>

        <div className="LICounter">
          <LiveinternetCounter />
        </div>

      </footer>
    )
  }
}
