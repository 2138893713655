import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const tN = 'components.TopBarAlerts.'

class TopBarAlerts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contact_person: true
    }
  }

  render() {
    const { user, isAuthenticated } = this.props.session

    return (
      <div id="topBarAlerts">
        { user.id && this.state.contact_person && user.contact_people.length == 0 &&
          <div className="b-topbaralert">
      			<div className="b-topbaralert__wrapper">
	            <div className="b-topbaralert__message">
	              { _t(tN + 'noticeFirstPart') } <Link to="/profile/info/edit">{ _t(tN + 'noticeLinkPart') }</Link>.
	            </div>

	            <div className="b-topbaralert__close" onClick={ e => { this.setState({ contact_person: false })}}>
	              <a>{ _t('close') }</a>
	            </div>
      			</div>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.auth
  }
}

export default connect(mapStateToProps)(TopBarAlerts)
