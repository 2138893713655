import cachios from 'cachios'
import axios from 'utils/axios'

const cachiosInstance = cachios.create(axios)
cachiosInstance.getCacheIdentifier = function (config) {
  return {
    method: config.method,
    url: config.url,
    params: config.params,
    data: config.data,
    locale: this.axiosInstance.defaults.headers.common.Locale,
  }
}

export default cachiosInstance
