import React, { Component } from 'react'
import { getUserLocale } from 'utils/user_locale_storage'

class LocaleBlock extends Component {

  constructor(props) {
    super(props)
    this.changeLanguage = this.changeLanguage.bind(this)
  }

  changeLanguage(lng) {
    i18n.changeLanguage(lng)
  }

  render() {
    const locale = getUserLocale() || 'ru'

    return (
      <div style={ { display: 'ruby' } }>
        <p>CURRENT LNG: { locale.lng }</p>
        <p>{ i18n.t('hello') }</p>

        <button onClick={ e => this.changeLanguage('ru') }>RU</button>
        <button onClick={ e => this.changeLanguage('en') } >EN</button>
      </div>
    )
  }
}


export default LocaleBlock
