import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class MainLink extends Component {
  render() {
    const props = this.props
    if (app_type == 'main') {
      return(
        <Link to={props.to} className={props.className}>{props.children}</Link>
      )
    } else {
      return (
        <a href={BACKEND_HOST + props.to} className={props.className} target="_blank">{props.children}</a>
      )
    }
  }
}

export default MainLink
