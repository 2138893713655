import React, { Component } from 'react'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { BrowserRouter } from 'react-router-dom'
import App from 'components/App'
import reducer from 'reducers'
import { setCurrentUser } from 'actions/auth'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)))

class Root extends Component {

  render() {
    store.dispatch(setCurrentUser(this.props.user))

    return (
      <Provider store={ store }>
        <BrowserRouter onUpdate={ () => window.scrollTo(0, 0) }>
          <App />
        </BrowserRouter>
      </Provider>
    )
  }
}

export default Root
