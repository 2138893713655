import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import MainLink from 'components/helpers/MainLink'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import BankImage from 'components/BankImage'

const tN = 'components.users.UserInfo.'

class UserInfo extends Component {

  popoverRating(rating) {
    let message = ''

    if (rating >= 69) {
      message = _t('goldRating')
    } else if (rating >= 55 && rating < 69) {
      message = _t('silverRating')
    } else if (rating < 55) {
      message = _t('bronzeRating')
    }

    return (
      <Popover id="popover-positioned-bottom4">
        { message }
      </Popover>
    )
  }

  popoverIcon(rating) {
    if (rating >= 69) return 'gold'
    if (rating >= 55 && rating < 69) return 'silver'
    if (rating <55) return 'bronze'
  }

  render() {
    const { isAuthenticated, user } = this.props.auth
    const pathToUser = '/users/' + user.id

    const userLinks = isAuthenticated ? (
      <span className="b-userlinks__wrapper">
        <OverlayTrigger placement="top" overlay={ this.popoverRating(user.rating) }>
          <MainLink to={ pathToUser } className={ `b-userlinks__userimage ${this.popoverIcon(user.rating)}` }>
            <BankImage
              file={ user.avatar }
              size="80x80"
              className="b-userlinks__userimagesrc"
              alt={ user.name }
              replacement={ require("images/icons/icon001.png") }
            />
          </MainLink>
        </OverlayTrigger>

        <MainLink to={ pathToUser } className="b-userlinks__userlink">{ user.name }</MainLink>

        { user.empty_fields_count > 0 &&
          <MainLink
            className="b-userlinks__emptyprofile"
            to={ pathToUser }
            title={ _t(tN + 'messageAboutEmptyFields', { count: user.empty_fields_count }) }
          >
            <span className="b-userlinks__emptyprofilenumber">{ user.empty_fields_count }</span>
          </MainLink>
        }

        <a
          href={BACKEND_HOST + '/logout'}
          className="b-userlinks__logout"
          title={ _t('logout') }
        >
          { _t('logout') }
        </a>
      </span>
    ) : (
      <span className="b-userlinks__wrapper">
        <MainLink to="/signup" className="b-userlinks__link">{ _t('signup') }</MainLink>
        <MainLink to="/login" className="b-userlinks__link">{ _t(tN + 'login') }</MainLink>
      </span>
    );

    return (
      <div className="b-userlinks">
        { userLinks }
      </div>
    )
  }
}

UserInfo.propTypes = {
  auth: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, null)(withRouter(UserInfo))
