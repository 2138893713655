import { SET_CURRENT_USER } from '../actions/types'
import { getCurrentUser as getCurrentUserRequest } from 'api/sessions'

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user: user
  }
}

export function getCurrentUser() {
  return dispatch => {
    return getCurrentUserRequest()
      .then(res => {
        dispatch(setCurrentUser(res.data.data))
      })
      .catch(err => {
        dispatch(setCurrentUser({}))
      })
  }
}

export function login(data) {
  return dispatch => {
    return axios.post('/api/v1/sessions/create', data).then(res => {
      const user = res.data.data.user

      if (user) {
        dispatch(setCurrentUser(user))
      }
    })
  }
}

export function changeCurrentUser(user) {
  return dispatch => {
    dispatch(setCurrentUser(user))
  }
}
