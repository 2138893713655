import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'

import formInputValueToObject from 'utils/formInputValueToObject'
import standardServerErrorHandler from 'utils/standardServerErrorHandler'

const tN = 'components.Feedback.'
const tN2 = 'models.feedback.'

export default class Feedback extends Component {

  constructor(props) {
    super(props)
    this.state = this.getInitialState(props)
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.standardServerErrorHandler = standardServerErrorHandler.bind(this)
  }

  getInitialState(props) {
    return {
      feedback: {
        name: '',
        contact: '',
        text: ''
      },
      showModal: false,
      errors: [],
      isLoading: false,
      isSuccess: false
    }
  }

  open() {
    this.setState({ showModal: true })
  }

  close() {
    if (this.state.isSuccess) {
      this.setState(this.getInitialState())
    } else {
      this.setState({ showModal: false })
    }
  }

  onChange(e) {
    var feedbackState = this.state.feedback
    formInputValueToObject(e.target.name, e.target.value, feedbackState)
    this.setState({ feedback: feedbackState })
  }

  onSubmit(e) {
    var that = this
    e.preventDefault()
    that.setState({ isLoading: true, isSuccess: false, errors: [] })

    axios.post('/api/v1/feedbacks', { feedback: that.state.feedback }).then(response => {
      that.setState({ isLoading: false, isSuccess: true, errors: [] })
    }).catch(err => {
      that.standardServerErrorHandler(err)
    })
  }

  render() {
    const { feedback, showModal, errors, isLoading, isSuccess } = this.state

    return (
      <li>
        <a onClick={ this.open }>{ _t('feedback') }</a>
        <Modal show={ this.state.showModal } onHide={ this.close } className="b-feedbackform">
          <Modal.Header>
            <Modal.Title>{ _t('feedback') }</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            { isSuccess ?
              <div className="b-form">
                <p className="b-form__subtext">{ _t(tN + 'successMessage') }</p>
              </div>
            :
              <form id="feedback-form" className="b-form">
                <div className="b-form__item">
                  <input
                    className="b-form__input"
                    type="text"
                    id="feedback_name"
                    name="name"
                    value={ feedback.name }
                    onChange={ this.onChange }
                    placeholder={ _t(tN2 + 'name') }
                  />
                </div>

                <div className="b-form__item">
                  <input
                    className="b-form__input"
                    type="text"
                    id="feedback_contact"
                    name="contact"
                    value={ feedback.contact }
                    onChange={ this.onChange }
                    placeholder={ _t(tN2 + 'contact') }
                  />
                </div>

                <div className="b-form__item">
                  <textarea
                    className="b-form__textarea"
                    id="feedback_text"
                    name="text"
                    onChange={ this.onChange }
                    value={ feedback.text }
                    placeholder={ _t(tN2 + 'text') }
                  />
                </div>

                <input
                  className="b-form__button"
                  type="button"
                  disabled={ isLoading }
                  onClick={ this.onSubmit }
                  defaultValue={ _t('send') }
                />

                { errors.length > 0 &&
                  <React.Fragment>
                    { errors.map(function(error, index) {
                      return (
                        <p key={index} className="b-form__subtext">
                          {error}
                        </p>
                      )
                    })}
                  </React.Fragment>
                }
              </form>
            }
          </Modal.Body>

          <Modal.Footer><a className="feedbackPopup__close" onClick={ this.close }>{ _t('close') }</a></Modal.Footer>
        </Modal>
      </li>
    )
  }
}
