import i18next from 'i18next'
import Backend from 'i18next-xhr-backend'
import { getUserLocale } from 'utils/user_locale_storage'

const i18n = i18next.createInstance()

i18n
  .use(Backend)
  .init({
    lng: getUserLocale() || 'ru',
    fallbackLng: 'ru',
    debug: true,
    backend: {
      loadPath: '/i18n/{{lng}}.json'
    }
  }).catch(err => {
    console.log(err)
  })

export default i18n
