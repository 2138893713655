import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MainLink from 'components/helpers/MainLink'
import UserInfo from 'components/users/UserInfo'
import LocaleBlock from 'components/LocaleBlock'
import { withRouter } from 'react-router'

class Header extends Component {
  render() {
    return (
      <div className="b-header__content">
        { this.props.location.pathname == '/' ?
          <h1 className="b-logo">
            <MainLink to="/" className="b-logo__link">rosagrodel<span>.ru</span></MainLink>
          </h1>
        :
          <div className="b-logo">
            <MainLink to="/" className="b-logo__link">rosagrodel<span>.ru</span></MainLink>
          </div>
        }

        { app_type == 'main' &&
          <div className="b-userbutton">
            <Link className="b-userbutton__link" to="/profile/items/create">{ _t('createItem') }</Link>
            <Link className="b-userbutton__link2" to="/profile/job/resumes/create">{ _t('createResume') }</Link>
          </div>
        }

        <UserInfo/>

        { localStorage.getItem('enableI18n') &&
          <LocaleBlock/>
        }
      </div>
    )
  }
}

export default withRouter(Header)
